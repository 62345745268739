.navbar {
  width: 100%;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: 0 1 auto;
}

.container_background {
  background-color: black;
}

.icon {
  width: 50px;
  height: inherit;
}

.text_color {
  color: #fa9c1e;
}

a.link {
  text-decoration: none;
}

.inherit_color {
  color: inherit;
}

:global(.MuiMenu-list) {
  background-color: black;
}
