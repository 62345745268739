.footer {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 1 auto;
}

.text_box {
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 2%;
}

.url {
  margin-left: 0px;
  margin-right: 3px;
}
