.App {
  text-align: center;
  display: flex;
  height: 100%;
  width: 100%;
}

.header {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2%;
}

.text_color {
  color: #fa9c1e !important;
}
