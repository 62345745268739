.text_color {
  color: #fa9c1e !important;
}

.table_cell_word {
  font-size: 50;
  vertical-align: middle;
}

.icons {
  width: 50px;
  display: inline-block;
  vertical-align: middle;
}